import utils from "../../common/utils";

const firebaseConfig = {
  apiKey: process.env.REACT_FIREBASE_API_KEY,
  authDomain: process.env.REACT_FIREBASE_API_AUTHDOMAIN,
  projectId: process.env.REACT_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_FIREBASE_API_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_FIREBASE_API_MESSAGINGSENDERID,
  appId: process.env.REACT_FIREBASE_API_APPID,
  measurementId: process.env.REACT_FIREBASE_API_MEASUREMENTID,
};
export const config = utils.base64URLEncode(JSON.stringify(firebaseConfig));
