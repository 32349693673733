import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { isMobile } from "react-device-detect";
import { CHAIN_ID } from "constant/environment";

const btnClass = "btn btn-md btn-primary text-sm";

const truncateAddress = (address: string | undefined) => {
  return address && `${address.slice(0, 6)}...${address.slice(-6)}`;
};

/// Dev: ref https://wagmi.sh/react/hooks/useSwitchNetwork
/// https://wagmi.sh/examples/connect-wallet#step-1-configuring-connectors

export function RawWalletConnect() {
  const { address, isConnected } = useAccount();
  const { data: ensAvatar } = useEnsAvatar({ name: address! }); // nice to use later
  const { data: ensName } = useEnsName({ address });
  const { connect, connectors: connectorsDefault, isLoading } = useConnect();

  const { chain: currentChain } = useNetwork();

  const { isLoading: isLoadingNetwork, switchNetwork } = useSwitchNetwork();

  let connectors = connectorsDefault;

  // if (isMobile) {
  //   connectors = connectorsDefault.filter(
  //     (connector) => connector.id === "walletConnect",
  //   );
  // } else {
  //   connectors = connectorsDefault.filter(
  //     (connector) => connector.id === "metaMask",
  //   );
  // }

  connectors = connectorsDefault.filter(
    (connector) => connector.id === "metaMask"
  );

  const { disconnect } = useDisconnect(); // add disconnect later

  if (currentChain && currentChain.id !== CHAIN_ID) {
    return (
      <button
        className={`${btnClass} btn-error`}
        onClick={() => {
          switchNetwork?.(CHAIN_ID);
        }}
        disabled={isLoadingNetwork}
      >
        {isLoadingNetwork && (
          <i className="fa-solid fa-circle-notch fa-spin text-lg mr-3"></i>
        )}
        <i className="fa-solid fa-triangle-exclamation text-lg mr-3"></i>
        <span className="">Switch to zkSync</span>
      </button>
    );
  }

  if (isConnected) {
    return (
      <button
        className="btn btn-md text-sm"
        onClick={() => {
          disconnect();
        }}
      >
        <span className="mr-3">
          {ensName
            ? `${ensName} (${truncateAddress(address)})`
            : truncateAddress(address)}
        </span>
        <i className="fa-regular fa-right-from-bracket"></i>
      </button>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        {connectors.map((connector) => {
          if (!connector.ready)
            return (
              <button
                className={btnClass}
                onClick={() => {
                  window.open("https://metamask.io/download/");
                }}
              >
                Install metamask
              </button>
            );

          return (
            <button
              className={btnClass}
              disabled={!connector.ready}
              key={connector.id}
              onClick={() => connect({ connector })}
            >
              {isLoading && (
                <i className="fa-solid fa-circle-notch fa-spin text-lg mr-3"></i>
              )}
              <i className="fa-duotone fa-wallet mr-3 text-lg"></i>
              <span>Connect wallet</span>
            </button>
          );
        })}
      </div>
    </>
  );
}
