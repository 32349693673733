import { Fragment, useEffect, useRef, useState } from "react";
import BgVideo from "assets/wallpapers/soccer.mp4";
import { ReactComponent as LogoMobile } from "assets/logo/logo-dark-goal3.svg";
import { walletClientToSigner } from "hooks/useSigner";
import { toast } from "react-toastify";
import Swap from "contracts/abi/swap.json";
import {
  useSignMessage,
  useAccount,
  useConnect,
  useSwitchNetwork,
  useDisconnect,
  useWalletClient,
  useContractWrite,
  usePublicClient,
} from "wagmi";
import { sleep } from "zksync-web3/build/src/utils";
import { CHAIN_ID, SWAP_CONTRACT, ZKUSD_CONTRACT } from "constant";
import { SiweMessage } from "siwe";
import { RawWalletConnect } from "components/organisms/Wallets/RawWalletConnect";
import {
  isAddress,
  TransactionExecutionError,
  UserRejectedRequestError,
} from "viem";
import { useZKUSDBalance } from "hooks/useBalance";
import { balance_contract } from "contracts/Contract";
import { useAllowance, useSwap } from "hooks/useSwap";
import { formatNumber } from "utils/number/number";
import { ethers } from "ethers";
import { watchAccount } from "@wagmi/core";
import Toast from "components/commons/Toast";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
function App() {
  const { disconnectAsync: disconnect } = useDisconnect();
  const [isLogin, setIsLogin] = useState(false);
  const [withDraw, setWithDraw] = useState(false);
  const [isLoadingMetamask, setIsLoadingMetamask] = useState(false);
  const [walletLoading, setWalletLoading] = useState<boolean>(false);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const { signMessageAsync } = useSignMessage();
  const { isConnected, status, address, isDisconnected } = useAccount();
  const { balanceOf } = useZKUSDBalance(address as string);
  const { data: signer } = useWalletClient();
  const { data } = useSwap(address as string, balanceOf);
  const { data: allowance } = useAllowance(address as string, SWAP_CONTRACT);
  const client = usePublicClient();
  const searchParams = new URLSearchParams(window.location.search);
  const mobileMeta = searchParams.get("w");
  useEffect(() => {
    if (mobileMeta === "1") {
      setWithDraw(true);
    }
  }, [mobileMeta]);
  useEffect(() => {
    const unwatch = watchAccount(
      (account) => {
        if (address && account.address && account.address !== address) {
          console.log("🚀 ~ selector ~ account", account);
        }
      },
      {
        selector({ address, connector }) {
          connector?.on("change", (account) => {
            if (address && account.account && account.account !== address) {
              disconnect();
            }
          });
        },
      }
    );

    // Cleanup by calling unwatch to unsubscribe from the account change event
    return () => unwatch();
  }, []);
  const { connect, connectors } = useConnect({
    onSuccess: async (data) => {
      const signer = await data.connector?.getWalletClient();
      if (!signer) {
        toast.error("Error code: CEF"); // check exist failure ❌
        return;
      } else {
        const _signer = walletClientToSigner(signer as any);
        if ((await _signer.getChainId()) !== CHAIN_ID) {
          await switchNetworkAsync?.(CHAIN_ID);
        }
        const statement =
          "Welcome. By signing this message you are verifying your digital identity. This is completely secure and does not cost anything!";

        const message = new SiweMessage({
          domain: window.location.host,
          address,
          statement,
          uri: window.location.origin,
          version: "1",
          chainId: CHAIN_ID,
        });
        let signature;
        try {
          signature = await signMessageAsync({
            message: message.prepareMessage(),
          });
        } catch (error: any) {
          console.log(error);
          if (error instanceof UserRejectedRequestError) {
            toast.error("User rejected the request."); // check exist failure ❌
          }
          disconnect();
          setIsLoadingMetamask(false);
          setIsLogin(false);
        }
        console.log("🚀 ~ onSuccess: ~ signature:", signature);

        if (!signature) {
          disconnect();
          return;
        } else {
          setIsLoadingMetamask(false);
          setIsLogin(true);
        }
      }
    },
    onSettled: () => {
      setIsLoadingMetamask(false);
    },
    onError: () => {
      toast.error("Connect failed"); // check exist failure ❌
    },
  });
  const { switchNetworkAsync } = useSwitchNetwork();

  const connectorMetamask = connectors.find(
    (connector) => connector.id === "metaMask"
  );

  const handleConnect = async () => {
    setIsLoadingMetamask(true);
    await disconnect();
    await sleep(200);
    nextButtonRef.current?.click();
  };
  const {
    data: hash,
    isLoading,
    isError,
    isSuccess,
    writeAsync,
    error,
  } = useContractWrite({
    abi: Swap.abi,
    address: SWAP_CONTRACT,
    functionName: "claim",
    args: [],
  });
  const handleWithdraw = async () => {
    try {
      setWalletLoading(true);
      console.log("🚀 ~ handleWithdraw ~ balanceOf", {
        balanceOf,
        allowance: Number(
          ethers.utils.formatUnits(allowance?.toString() || "", 6)
        ),
      });
      if (
        !allowance ||
        (allowance &&
          Number(ethers.utils.formatUnits(allowance.toString(), 6)) < balanceOf)
      ) {
        const zkUSDAddress = process.env.REACT_APP_SMC_ZKUSD;
        const _signer = walletClientToSigner(signer as any);
        const zkContract = balance_contract(zkUSDAddress, _signer);
        const approval = await zkContract.approve(
          SWAP_CONTRACT,
          ethers.utils.parseUnits(balanceOf.toString(), 6)
        );
        await approval.wait();
        if (approval) {
          await writeAsync?.();
        }
        console.log("🚀 ~ handleWithdraw ~ approval:", approval);
      } else {
        await writeAsync?.();
      }
    } catch (error) {
      console.log("🚀 ~ handleWithdraw ~ error:", error);
      if (error instanceof UserRejectedRequestError) {
        toast.error("User rejected the request."); // check exist failure ❌
      }
      if (error instanceof TransactionExecutionError) {
        toast.error(`Error: user rejected transaction`);
      }
    } finally {
      setWalletLoading(false);
    }
  };
  if (isError && !isLoading) {
    console.log(error);
    walletLoading && setWalletLoading(false);
  }
  if (isSuccess && !isLoading) {
    toast.success("Withdraw success");
  }
  return (
    <Fragment>
      {!withDraw ? (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-5 z-0 w-screen h-screen  bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              {/* <source type="video/webm" src={`/src/assets/wallpapers/dark/waves.webm`} /> */}
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div className="w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative">
              <div className="flex mx-auto w-full max-w-lg md:bg-gray-800/40 rounded-2xl">
                <div className="w-full flex flex-col px-8 py-8">
                  <div className="flex flex-shrink-0 justify-start">
                    <div className="flex items-center">
                      <LogoMobile className="w-[132px] h-8 cursor-pointer" />
                    </div>
                  </div>

                  <h2 className="text-2xl font-semibold mt-4">
                    It has been a wild ride!
                  </h2>

                  <div className="leading-relaxed mt-4 text-sm text-gray-400">
                    Due to the uncertain landscape, we have decided to sunset
                    the Goal3 Protocol and goal3.xyz frontend. This decision has
                    not been made lightly, but we believe it is the best course
                    of action to all parties involved.
                  </div>
                  <div className="leading-relaxed mt-2 text-sm text-gray-400">
                    For existing users, please redeem your zkUSD into USDC as
                    soon as possible. Goodbye, and thank you once again for
                    being a part of our journey.{" "}
                    <a
                      href={
                        "https://x.com/goal3_xyz/status/1803410908049449388?s=46&t=Xq7c8x2pBX4oyehL2X2zZg"
                      }
                      className="text-primary"
                      target={"_blank"}
                    >
                      More details
                    </a>
                  </div>

                  <div className="mt-8 flex gap-4 w-full">
                    {isMobile ? (
                      <button
                        onClick={() =>
                          window.open(
                            `https://metamask.app.link/dapp/${window.location.origin}?w=1`
                            // `${window.location.origin}${slugs.metamask}/${token}`
                          )
                        }
                        className="btn btn-md btn-primary justify-between gap-3"
                      >
                        <span className="flex items-center gap-3">
                          <i className="fa-solid fa-arrow-up-from-bracket text-base"></i>
                          <span className="text-sm">Withdraw</span>
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => setWithDraw(true)}
                        className="btn btn-md btn-primary !h-2.5 justify-between gap-3"
                      >
                        <span className="flex items-center gap-3">
                          <span className="text-sm">Withdraw your assets</span>
                          <i className="fa-solid fa-angle-right text-base"></i>
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          data-theme="dark"
          className="main-layout--wrapper !max-w-none bg-gradient-to-b from-gray-800 to-gray-950 text-base-content/50 overflow-hidden"
        >
          <div className="fixed inset-0 opacity-10 z-0 w-screen h-screen bg-cover">
            <video
              className="object-cover w-full h-screen"
              autoPlay
              playsInline
              loop
              muted
            >
              <source type="video/webm" src={BgVideo} />
            </video>
          </div>

          <div className="main-layout">
            <div className="w-full h-[calc(var(--vh,1vh)*100)] flex items-center justify-center flex-col z-0 relative">
              <div className="flex mx-auto w-full max-w-lg md:bg-gray-800/40 rounded-2xl">
                <div className="flex flex-col px-8 py-8">
                  <div className="flex flex-shrink-0 justify-start">
                    <div className="flex items-center">
                      <LogoMobile className="w-[132px] h-8 cursor-pointer" />
                    </div>
                  </div>
                  <h2 className="text-2xl font-semibold mt-4">
                    Withdraw your assets
                  </h2>

                  <ul className="steps steps-sm steps-vertical mt-2">
                    <li
                      className={`step relative z-10 ${!isConnected || !address || isLoadingMetamask
                          ? "step-primary"
                          : "step-neutral"
                        }`}
                    >
                      <span className="flex flex-col justify-start items-start">
                        {isConnected && address && !isLoadingMetamask ? (
                          <RawWalletConnect />
                        ) : (
                          <>
                            <button
                              className="btn btn-md justify-between gap-3"
                              disabled={isLoadingMetamask}
                              onClick={handleConnect}
                            >
                              <span className="flex items-center gap-3">
                                {isLoadingMetamask ? (
                                  <i className="fa-solid fa-spinner-third animate-spin"></i>
                                ) : (
                                  <i className="fa-duotone fa-wallet icon-primary text-lg"></i>
                                )}
                                <span className="text-base">
                                  Connect Wallet
                                </span>
                              </span>
                              <span>
                                <i className="fa-solid fa-angle-right"></i>
                              </span>
                            </button>

                            <button
                              ref={nextButtonRef}
                              className="hidden"
                              onClick={() => {
                                connect({ connector: connectorMetamask });
                              }}
                            ></button>
                          </>
                        )}
                      </span>
                    </li>
                    <li
                      className={`step step-neutral -mt-2 ${!isConnected || !address || isLoadingMetamask
                          ? "step-neutral disabled"
                          : "step-primary"
                        }`}
                    >
                      {!isConnected || !address || isLoadingMetamask ? (
                        <span className="flex flex-col justify-start items-start text-left pt-4">
                          <strong className="text-base-content">
                            Claim your assets
                          </strong>
                          <span className="text-sm text-gray-400">
                            Please connect your Goal3 wallet first
                          </span>
                        </span>
                      ) : (
                        <span className="flex flex-col justify-start items-start text-left pt-4">
                          <strong className="text-base-content">
                            Withdraw your assets
                          </strong>
                          <span className="text-sm text-gray-400">
                            Swap your remain zkUSD to USDC
                          </span>
                          {!hash ? (
                            <button
                              className={`btn btn-md btn-primary justify-between gap-3 mt-4${isLoading || walletLoading
                                  ? " disabled no-animation"
                                  : ""
                                }`}
                              onClick={handleWithdraw}
                              disabled={
                                isLoading || balanceOf === 0 || walletLoading
                              }
                            >
                              <span
                                className="flex items-center gap-3 tooltip"
                                data-tip={`${isLoading
                                    ? " Withdraw in progress..."
                                    : ` Withdraw ${formatNumber(
                                      balanceOf
                                    )} zkUSD`
                                  }`}
                              >
                                {isLoading || walletLoading ? (
                                  <>
                                    <i className="fa-solid fa-circle-notch fa-spin text-base"></i>
                                    <span className="text-sm">
                                      Withdraw in progress...
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <i className="fa-solid fa-arrow-up-from-bracket text-base"></i>
                                    <span className="text-sm">
                                      Withdraw {formatNumber(balanceOf)} zkUSD
                                    </span>
                                  </>
                                )}
                              </span>
                            </button>
                          ) : (
                            <span className="inline-flex items-center rounded-box px-4 h-10 border border-success text-success justify-between gap-3 mt-4 no-animation cursor-default">
                              <span className="flex items-center gap-3">
                                <i className="fa-solid fa-check text-base"></i>
                                <span className="text-sm">
                                  Withdraw successfully
                                </span>
                              </span>
                            </span>
                          )}
                        </span>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Toast />
        </div>
      )}
    </Fragment>
  );
}

export default App;
