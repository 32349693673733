import * as React from "react";
import { type WalletClient, useWalletClient } from "wagmi";
import { providers } from "ethers";
import { Web3Provider } from "zksync-web3";

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, transport } = walletClient;
  // const network = {
  //   chainId: chain?.id,
  //   name: chain?.name,
  //   ensAddress: chain?.contracts?.ensRegistry?.address,
  // };
  const provider = new Web3Provider(transport);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId });
  return React.useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : undefined),
    [walletClient]
  );
}
