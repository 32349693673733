import { proxy } from "valtio";
import { DocumentData, DocumentSnapshot, onSnapshot } from "firebase/firestore";
import { getGlobalConfig, getThreshold } from "../utils/firebase/firebaseDBRef";

let unsubscribe: any;
let unsubscribe2: any;
export interface Threshold {
  [sport: string]: {
    [market: string]: {
      [threshold: string]: number;
    };
  };
}
class ConfigProxy {
  isLoading: boolean = false;
  appConfig: any = {};
  threshold: Threshold = {};
  subscribed: boolean = false;
  subscribed2: boolean = false;
  symbol: string = process.env.REACT_APP_SYMBOL || "zkUSD";
  decimals: number = parseInt(process.env.REACT_APP_DECIMALS || "6");

  loadConfig() {
    if (this.subscribed) return;
    this.subscribed = true;
    this.isLoading = true;

    const ref = getGlobalConfig();
    unsubscribe = onSnapshot(ref, (doc: DocumentSnapshot<DocumentData>) => {
      if (doc.exists()) {
        this.appConfig = doc.data();
      }
    });
  }
  loadThreshold() {
    if (this.subscribed2) return;
    this.subscribed2 = true;
    this.isLoading = true;

    const ref = getThreshold();
    unsubscribe2 = onSnapshot(ref, (doc: DocumentSnapshot<DocumentData>) => {
      if (doc.exists()) {
        this.threshold = doc.data();
      }
    });
  }

  unsubscribeDb() {
    if (unsubscribe) {
      unsubscribe();
      this.subscribed = false;
    }
    if (unsubscribe2) {
      unsubscribe2();
      this.subscribed2 = false;
    }
  }
}

export const configProxy = proxy(new ConfigProxy());
