import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobileOnly } from "react-device-detect";

const contextClass = {
  success: "bg-green-700 text-[#ffffff]",
  error: "bg-red-700 text-[#ffffff]",
  info: "bg-sky-700 text-[#ffffff]",
  warning: "bg-amber-700 text-[#ffffff]",
  default: "bg-primary text-primary-content",
};

const Toast = () => (
  <ToastContainer
    toastClassName={({ type }) =>
      contextClass[type || "default"] +
      " relative w-[60%] md:w-[100%] h-[44px]! flex p-2 min-h-8 rounded-box overflow-hidden cursor-pointer shadow-xl backdrop-blur mb-2 font-thin"
    }
    bodyClassName={() =>
      "flex flex-nowrap text-ellipsis overflow-hidden items-center text-sm font-normal w-full h-full"
    }
    position={isMobileOnly ? "bottom-center" : "top-center"}
    autoClose={3000}
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    toastStyle={{
      height: 44,
    }}
    style={{
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    }}
    theme="colored" //light|dark|colored
  />
);

export default Toast;
