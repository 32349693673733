import { useBlockNumber, useContractRead } from "wagmi";
import Swap from "contracts/abi/swap.json";
import zkUSD from "contracts/abi/zkUSD.json";
import { isAddress } from "viem";
import { SWAP_CONTRACT, ZKUSD_CONTRACT } from "constant";

export const useSwap = (address: string, balanceOf: number) => {
  const { data: blockNumber } = useBlockNumber();

  const query = useContractRead({
    abi: Swap.abi,
    address: SWAP_CONTRACT,
    functionName: "getAmount",
    args: [balanceOf!],
    enabled: isAddress(address),
  });
  return { ...query };
};
export const useAllowance = (address: string, spender: string) => {
  const query = useContractRead({
    abi: zkUSD.abi,
    address: ZKUSD_CONTRACT,
    functionName: "allowance",
    args: [address, spender],
    enabled: isAddress(address) && isAddress(spender),
  });
  return { ...query };
};
