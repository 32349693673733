import { useEffect, useState } from "react";
import { balance_contract_wtout_signer } from "../contracts/Contract";
import { formatBigNumber } from "../common/utils";
import { AcceptedTokens } from "../contracts/config";
import { useAccount } from "./useAccount";
import { useSnapshot } from "valtio";
import { configProxy } from "proxy-state/configProxy";
import { useBlockNumber } from "wagmi";
import { isAddress } from "viem";

interface Token {
  address: string;
  symbol: string;
  decimals: number;
  balance: number;
  // Add other properties as needed
}

export const useBalance = () => {
  const { address } = useAccount();
  const { symbol } = useSnapshot(configProxy);
  const [balance, setBalance] = useState<Token[]>([]);
  const [balanceOf, setBalanceOf] = useState<number>(0);

  const chainID = Number(process.env.REACT_APP_CHAIN_ID);
  const tokens: Token[] = AcceptedTokens[chainID] || [];

  useEffect(() => {
    if (!address) return;
    getBalance().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const getBalance = async () => {
    const balancePromises = tokens.map(async (token) => {
      const { address: acceptToken, decimals } = token;

      const blContract = balance_contract_wtout_signer(acceptToken);
      const myBalanceOf = await blContract.balanceOf(address);
      return {
        ...token,
        balance: Number(formatBigNumber(myBalanceOf, true, decimals)),
      };
    });

    const updatedBalance = await Promise.all(balancePromises);
    const findBalanceOf =
      updatedBalance?.filter((token) => token.symbol === symbol)?.[0]
        ?.balance || 0;

    setBalance(updatedBalance);
    setBalanceOf(findBalanceOf);
  };

  return {
    balance,
    balanceOf,
  };
};
export const useZKUSDBalance = (address: string) => {
  const [balanceOf, setBalanceOf] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const getZKUSD = async () => {
    try {
      if (!isAddress(address)) return 0;
      setLoading(true);
      const zkUSDAddress = process.env.REACT_APP_SMC_ZKUSD;
      const blContract = balance_contract_wtout_signer(zkUSDAddress);
      const myBalanceOf = await blContract.balanceOf(address);
      setBalanceOf(Number(formatBigNumber(myBalanceOf, true, 6)));
    } catch (error) {
      console.log(
        "🚀 ~ file: useBalance.tsx ~ line 108 ~ getZKUSD ~ error",
        error
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getZKUSD().then();
  }, [address]);
  return { balanceOf, loading };
};
