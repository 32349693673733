import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { ethers } from "ethers";
import { isMobile } from "react-device-detect";
const getNameByHeader = (header: any, data: any) => {
  let name = "";
  switch (header) {
    case "1":
      name = data?.home;
      break;
    case "2":
      name = data?.away;
      break;
    case "X":
    case "Draw":
      name = isMobile ? "x" : "Draw";
      break;
    default:
      name = header;
      break;
  }
  return name;
};
const utils = {
  createMetaData: (data: any) => {
    const metaData = {
      type: data?.type,
      site_name: data?.site_name || "Goal3",
      schemaType: data?.schemaType,
      url: data?.url,
      title: data?.title,
      description: data?.desc,
      keywords: data?.keywords,
      image: data?.image,
      image_alt: data?.image_alt,
      created_at: data?.created_at,
      updated_at: data?.updated_at,
    };

    return metaData;
  },

  buildHomeMetaData: () => {
    const metaData = {
      title: "Goal3.gg - The first decentralized Sportsbook on zkSync Era",
      site_name: "Goal3",
      description:
        "Experience the Future of Sport Betting with Goal3 - Powered by zkEVM. Join the GambleFi movement and unleash the power of decentralized betting!",
      keywords: "sportsbook, zkSync, betting, web3, blockchain.",
      image: `${process.env.PUBLIC_URL}/meta/default.jpg`,
      image_alt: "Goal3",
      url: `${process.env.PUBLIC_URL}`,
      type: "website",
      schemaType: "Website",
      created_at: "2023-02-20T15:55:10.742Z",
      updated_at: "2023-02-20T15:55:10.742Z",
    };

    return metaData;
  },

  htmlToString: (html: any) => {
    const str = html.replace(/(<([^>]+)>)/gi, "").replace(/(\r\n|\n|\r)/gm, "");
    if (str.length > 250) {
      return str.substring(0, 250) + "...";
    }
    return str;
  },

  getStatusConvert: (status: any) => {
    const _status = ["active", "pending", "locked", "end", "refund"];
    return _status[status];
  },
  handleSwitchNetwork: (chainId: number, type: string) => {
    if (chainId === 1) {
      return type === "name" ? "Ethereum" : "ethereum";
    }
    if (chainId === 3) {
      return type === "name" ? "Ropsten Testnet" : "ethereum";
    }
    if (chainId === 4) {
      return type === "name" ? "Rinkeby Testnet" : "ethereum";
    }
    if (chainId === 5) {
      return type === "name" ? "Goerli Testnet" : "ethereum";
    }
    if (chainId === 42) {
      return type === "name" ? "Kovan Testnet" : "bnb";
    }
    if (chainId === 56) {
      return type === "name" ? "Binance Smart Chain" : "bnb";
    }
    if (chainId === 97) {
      return type === "name" ? "Binance Smart Chain Testnet" : "bnb";
    }
    if (chainId === 10) {
      return type === "name" ? "Optimism" : "optimism";
    }
    if (chainId === 420) {
      return type === "name" ? "Optimism Testnet" : "optimism";
    }
    if (chainId === 137) {
      return type === "name" ? "Polygon" : "polygon";
    }
    if (chainId === 80001) {
      return type === "name" ? "Polygon Testnet" : "polygon";
    }
    return type === "name" ? "localhost" : "bnb";
  },
  requestSwitchNetwork: async (dataChain: any) => {
    let errorMesage: any = {};
    if (!(window as any)?.ethereum?.isConnected()) return {};

    try {
      await (window as any).ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: dataChain.NEXT_PUBLIC_CHAIN_ID_HEX }],
      });
    } catch (switchError: any) {
      if (process.env.NODE_ENV !== "production") {
        console.log("switchNetworkError", switchError);
      }

      if (switchError.code === 4902) {
        try {
          await (window as any).ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: dataChain.NEXT_PUBLIC_CHAIN_ID_HEX,
                chainName: dataChain.NEXT_PUBLIC_CHAIN_NAME,
                rpcUrls: [dataChain.NEXT_PUBLIC_RPC_URL],
                nativeCurrency: {
                  name: dataChain.NEXT_PUBLIC_CHAIN_CURENCY_NAME,
                  symbol: dataChain.NEXT_PUBLIC_CHAIN_CURENCY_SYMBOL,
                  decimals: Number(
                    dataChain.NEXT_PUBLIC_CHAIN_CURENCY_DECIMALS
                  ),
                },
              },
            ],
          });
        } catch (addError) {
          if (process.env.NODE_ENV !== "production") {
            console.log("addChainError", addError);
          }
          errorMesage = switchError;
        }
      }

      //User cancel switch chain case
      if (switchError.code === 4001) {
        errorMesage = switchError;
        toast.warning(switchError.message);
      }
    }
    return errorMesage;
  },
  getEventId: (slug: string): string => {
    const eventId = slug.split("-").pop() || "";
    return eventId;
  },
  getCategory: (slug: string): string => {
    const cate = slug.split("/")?.[1];
    return cate;
  },
  rebuildBetSlips: (betSlips: any, idx: any) => {
    let NewData = betSlips;
    if (idx > -1) {
      NewData = betSlips?.filter((b: any, index: any) => {
        return idx !== index;
      });
    }
    return NewData;
  },
  calculatePotentialWin: (value: number, price: number) => {
    return value * price;
  },
  reverseString: (str: string) => {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split(""); // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    var reverseArray = splitString.reverse(); // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    var joinArray = reverseArray.join(""); // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    //Step 4. Return the reversed string
    return joinArray; // "olleh"
  },
  reverse: (str: string) => {
    return str.split("-").reverse().join("-");
  },
  getOddName: (type: any, data: any, screenName: any) => {
    let name = "";
    const odd = data?.odds;
    switch (type) {
      case "correct_score":
        name =
          odd?.header === "1"
            ? odd?.name
            : odd?.header === "2"
            ? utils.reverse(odd.name)
            : odd?.name;
        break;
      case "half_time_full_time":
        name = odd?.name;
        break;
      case "goalscorers":
        name = odd?.name;
        name += " ( " + odd?.header + " )";
        break;
      case "game_lines":
      case "match_lines":
        name = screenName
          ? odd?.name
          : odd?.header === "Draw"
          ? "x"
          : odd?.header;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        odd?.handicap ? (name += "(" + odd?.handicap + ")") : "";
        break;

      case "number_of_cards_in_match":
      case "goals_over_under":
      case "alternative_total_goal":
      case "total_rounds":
        name = odd?.header;
        name += " (" + odd?.name + ")";
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        odd?.handicap ? (name += " (" + odd?.handicap + ")") : "";
        break;
      case "result_total_goals":
        name = odd?.header;
        name += "(" + odd?.handicap + ")";
        break;
      case "winning_margin":
      case "number_of_goals_in_match":
      case "both_teams_to_score":
        name = odd?.name;
        break;
      case "draw_no_bet":
      case "full_time_result":
      case "corner_match_bet":
      case "most_hits":
      case "to_win_fight":
        name = screenName
          ? odd?.name === "1"
            ? data?.home
            : odd?.name === "2"
            ? data?.away
            : odd?.name
          : odd?.name === "Draw"
          ? "x"
          : odd?.name;
        break;
      case "run_line":
      case "money_line_and_total":
      case "asian_handicap":
        name = screenName
          ? odd?.header === "1"
            ? data?.home
            : odd?.header === "2"
            ? data?.away
            : odd?.name
          : odd?.name === "Draw"
          ? "x"
          : odd?.header;
        name += " ( " + odd?.handicap + " )";
        break;
      // case "match_handicap_and_total":
      // 	name = screenName ? (odd?.header == '1' ? data?.home : (odd?.header == '2' ? data?.away : odd?.name)) : (odd?.name == 'Draw' ? 'x' : odd?.name);
      // 	break;
      case "match_handicap_and_total":
        name = odd?.name;
        break;
      case "money_line":
        name = screenName
          ? odd?.header === "1"
            ? data?.home
            : odd?.header === "2"
            ? data?.away
            : odd?.name
          : odd?.name === "Draw"
          ? "x"
          : odd?.header;
        break;
      case "match_result_and_total":
      case "total":
      case "spread":
      case "match_handicap": //CS:GO
      case "total_map": //CS:GO
        name = odd?.handicap;
        break;
      // case "game_lines":
      // 	name = screenName ? (odd?.name == '1' ? data?.home : (odd?.name == '2' ? data?.away : odd?.name)) : (odd?.name == 'Draw' ? 'x' : odd?.name);
      // 	break;
      case "1st_half":
      case "1st_quarter":
      case "2nd_quarter":
      case "3rd_quarter":
      case "4th_quarter":
      case "game_lines_3_way":
        break;
      case "corners":
      case "total_hits":
        name = odd?.header;
        name += "(" + odd?.name + ")";
        break;
      case "to_away_score_in_both_halves":
      case "to_home_score_in_both_halves":
      case "score_in_both_halves":
      case "to_win_from_behind":
      case "to_win_both_halves":
      case "to_away_win_either_half":
      case "to_home_win_either_half":
      case "win_either_half":
      case "to_win_to_nil":
        name = screenName
          ? odd?.header === "1"
            ? data?.home
            : odd?.header === "2"
            ? data?.away
            : odd?.header
          : odd?.header === "Draw"
          ? "x"
          : odd?.header;
        break;
      case "total_corners":
        name = odd?.name;
        break;
      case "result_both_teams_to_score":
        name = odd?.header;
        break;
      // case "match_handicap":
      //   name = getNameByHeader(odd?.header, data);
      //   name += " (" + odd?.handicap + ")";
      //   break;
      // CS:GO
      case "to_win":
        name = getNameByHeader(odd?.header, data);
        break;
      default:
        name = odd?.name;
        break;
    }

    return name;
  },
  getOddNameByOrder: (type: any, data: any, screenName: any) => {
    let name = "";
    const odd = data?.odds;
    switch (type) {
      case "correct_score":
      case "half_time_full_time":
        name = odd?.name;
        break;
      case "goalscorers":
        name = odd?.name;
        name += " ( " + odd?.header + " )";
        break;
      case "game_lines":
      case "match_lines":
        name = screenName
          ? odd?.name
          : odd?.header === "Draw"
          ? "x"
          : odd?.header;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        odd?.handicap ? (name += "(" + odd?.handicap + ")") : "";
        break;
      case "goals_over_under":
      case "alternative_total_goal":
      case "total_rounds":
        name = odd?.header;
        name += " ( " + odd?.name + " )";
        if (odd?.handicap) {
          name += " (" + odd?.handicap + ")";
        }
        break;
      case "result_total_goals":
        name =
          odd?.name === "1"
            ? data?.home
            : odd?.name === "2"
            ? data?.away
            : odd?.name;
        name += " - " + odd?.header;
        name += "(" + odd?.handicap + ")";
        break;
      case "number_of_goals_in_match":
      case "both_teams_to_score":
        name = odd?.name;
        break;
      case "draw_no_bet":
      case "full_time_result":
        name = screenName
          ? odd?.name === "1"
            ? data?.home
            : odd?.name === "2"
            ? data?.away
            : odd?.name
          : odd?.name === "Draw"
          ? "x"
          : odd?.name;
        break;
      case "result_both_teams_to_score":
        name = screenName
          ? odd?.name === "1"
            ? data?.home
            : odd?.name === "2"
            ? data?.away
            : odd?.name
          : odd?.name === "Draw"
          ? "x"
          : odd?.name;
        name += " ( " + odd?.header + " )";
        break;
      case "asian_handicap":
      case "match_result_and_total":
        name = screenName
          ? odd?.header === "1"
            ? data?.home
            : odd?.header === "2"
            ? data?.away
            : odd?.name
          : odd?.name === "Draw"
          ? "x"
          : odd?.name;
        name += " (" + odd?.handicap + ")";
        break;
      case "match_handicap_and_total":
        name = screenName
          ? odd?.name
          : odd?.name?.includes(data?.home)
          ? odd?.name.replace(data?.home, "1")
          : odd?.name.replace(data?.away, "2");
        break;
      // case "game_lines":
      // 	name = screenName ? (odd?.name == '1' ? data?.home : (odd?.name == '2' ? data?.away : odd?.name)) : (odd?.name == 'Draw' ? 'x' : odd?.name);
      // 	break;
      case "spread":
      case "match_handicap":
        name = getNameByHeader(odd?.header, data);
        name += " (" + odd?.handicap + ")";
        break;
      case "winning_margin":
      case "winning_margins":
        name = screenName
          ? odd?.header === "1"
            ? data?.home
            : odd?.header === "2"
            ? data?.away
            : odd?.name
          : odd?.name === "Draw"
          ? "x"
          : odd?.header;
        name += "(" + odd?.name + ")";
        break;
      case "1st_half":
      case "1st_quarter":
      case "2nd_quarter":
      case "3rd_quarter":
      case "4th_quarter":
      case "game_lines_3_way":
        break;
      default:
        break;
    }

    return name;
  },
  _getOddName: (item: any) => {
    let _oddname = "";
    let _key: string = item?.marketKey || item?.key || "";
    if (!_key) return _oddname;
    if (_key.includes("alternative_total_goal"))
      _key = "alternative_total_goal";
    if (_key.includes("total_rounds")) _key = "total_rounds";
    switch (_key) {
      case "winning_margin":
      case "winning_margins":
      case "match_handicap_and_total":
      case "result_both_teams_to_score":
      case "match_result_and_total":
      case "alternative_total_goal":
      case "result_total_goals":
      case "total_rounds":
      case "match_handicap":
      case "spread":
        _oddname = utils.getOddNameByOrder(_key, item, "full");
        break;

      default:
        _oddname = utils.getOddName(_key, item, "full");
        break;
    }
    return _oddname;
  },
  base64URLDecode: (str: string) => {
    return atob(str);
  },
  base64URLEncode: (str: string) => {
    return btoa(str);
  },
  enscryptData: (data: string, key: string) => {
    const encrypted = CryptoJS.AES.encrypt(data, key).toString();
    return encrypted;
  },
  desencryptData: (data: string, key: string) => {
    const decrypted = CryptoJS.AES.decrypt(data, key);
    return decrypted.toString(CryptoJS.enc.Utf8);
  },
  FirstCapital: (str: string) => {
    if (!str) return "";
    str = str.replace(/-/g, " ");
    str = str.replace(/_/g, " ");
    const strArr = str.split(" ");
    str = strArr
      .map((item: string) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(" ");
    return str;
  },
  getEtherMessages: (code: string) => {
    let message = "";

    switch (code) {
      case "INVALID_ARGUMENT":
        message = "Invalid argument";
        break;
      case "INVALID_ARGUMENT_TYPE":
        message = "Invalid argument type";
        break;
      case "UNPREDICTABLE_GAS_LIMIT":
        message = "Unpredictable gas limit";
        break;
      case "INSUFFICIENT_FUNDS":
        message = "Insufficient funds";
        break;
      case "NONCE_EXPIRED":
        message = "Nonce expired";
        break;
      case "REPLACEMENT_UNDERPRICED":
        message = "Replacement underpriced";
        break;
      case "REJECTED_TRANSACTION":
        message = "user rejected transaction";
        break;
      case "TRANSACTION_RAN_OUT_OF_GAS":
        message = "The transaction gas limit as a string.";
        break;
      default:
        break;
    }
    return message;
  },
  removeProxy: (data: any) => {
    let newData = [];
    newData = data?.map((item: any) => {
      const newItem = { ...item };
      return newItem;
    });
    return newData;
  },
  getStatusOrder: (status: number) => {
    let statusName = "";
    switch (status) {
      case 0:
        statusName = "Open";
        break;
      case 1:
        statusName = "In-Play";
        break;
      case 2:
        statusName = "Wait for resolving";
        break;
      case 3:
        statusName = "Lost";
        break;
      case 4:
        statusName = "Won";
        break;
      case 5:
        statusName = "Refunded";
        break;
      default:
        statusName = "Open";
        break;
    }
    return statusName;
  },
  getEventName: (str: string) => {
    if (!str) return "";
    return str.length > 25 ? str.substring(0, 20) + "..." : str;
  },
  getOddWin: (markets: any) => {
    const win: string[] = [];
    if(!markets) return win;
    Object.keys(markets).forEach((key: any) => {
      const market = markets[key];
      market?.forEach((item: any) => {
        win.push(item?.win_odd_id);
      });
    });
    return win;
  },
};

export default utils;

export const ethView = (eth: string) => {
  const splitString = eth.split(".");
  return splitString[0] + "." + splitString[1].substring(0, 4);
};
export const ConvertString2BN = (value: any) => {
  return ethers.utils.parseUnits(Number(value).toFixed(0).toString(), 0);
};
export const formatBigNumber = (
  value: any,
  isDecimals = true,
  decimals = 6
) => {
  // convert to wei
  if (ethers.BigNumber.isBigNumber(value)) {
    if (isDecimals)
      return Number(ethers.utils.formatUnits(value, decimals)).toFixed(
        decimals
      );
    return ethers.utils.formatEther(value);
  }

  return value;
};

export const parseBigNumber = (
  value: number,
  isDecimals = true,
  _decimals = 6
) => {
  const decimals = 6;
  if (isDecimals)
    return ethers.utils.parseUnits(
      value.toFixed(_decimals || decimals).toString(),
      _decimals || decimals
    );
  return ethers.utils.parseEther(value.toString());
};

export const currencyFormat = (value: number, shorten = false): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: shorten ? "compact" : "standard",
    minimumFractionDigits: 4,
    // maximumFractionDigits: 2,
    minimumSignificantDigits: 4,
    // maximumSignificantDigits: 2
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // , // (causes 2500.99 to be printed as $2,501)
  });
  //add space after $ signing
  return formatter
    .formatToParts(value)
    .reduce(
      (result, part) =>
        part.type !== "fraction"
          ? result + part.value
          : result + part.value.substring(0, 2),
      ""
    )
    .replace("$", "");
};

export const currencyFormatWithoutSign = (value: number): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  //add space after $ signing
  return formatter.format(value).replace("$", "");
};

export const integerFormat = (value: number): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
};

export const floatFormat = (value: number, decimal: number = 2): string => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: decimal,
  });
  return formatter.format(value);
};

export const numberFixed = (value: any) => {
  if (!value) return 0;
  return Number(Number(value).toFixed(2));
};

export const NumberFormatDecimals = (value: any, decimals?: number) => {
  if (decimals) return Number(value) / 10 ** decimals;

  return Number(value);
};
export const getAddressSimpleString = (address: string, length = 3) => {
  if (!address) return "";

  return (
    address.substring(0, length) +
    "..." +
    address.substring(address.length - length, address.length)
  );
};
export const setSystemTypes = (length: number) => {
  if (length < 2) {
    return [];
  }
  const _events = generateNumberArray(length);
  const events = [];
  for (let i = 2; i < _events.length; i++) {
    events[i] = {
      name:
        i +
        "/" +
        _events.length +
        " (" +
        combinations(_events.length, i) +
        " Bets)",
      value: combinations(_events.length, i),
    };
  }
  events[_events.length + 1] = {
    name: getCombinationName(combinationsWithCombo(_events.length, 1)),
    value: combinationsWithCombo(_events.length, 1),
  };
  if (length < 7)
    events[_events.length + 2] = {
      name: getCombinationName(combinationsWithCombo(_events.length, 2)),
      value: combinationsWithCombo(_events.length, 2),
    };
  return events;
};

function combinationsWithCombo(n: number, r: number) {
  let result = 1;
  for (let i = 2; i < n; i++) {
    result += combinations(n, i);
  }
  if (r === 2) result += n;

  return result;
}
function getCombinationName(number: number) {
  let name = "";
  switch (number) {
    case 2:
      name = "Double";
      break;
    case 4:
      name = "Trixie";
      break;
    case 7:
      name = "Patent";
      break;
    case 11:
      name = "Yankee";
      break;
    case 15:
      name = "Lucky";
      break;
    case 26:
      name = "Canadian";
      break;
    case 31:
      name = "Lucky 31";
      break;
    case 57:
      name = "Heinz";
      break;
    case 63:
      name = "Lucky 63";
      break;
    case 120:
      name = "Super Heinz";
      break;
    case 247:
      name = "Goliath";
      break;
  }
  return name + ` (${number} Bets)`;
}
function generateNumberArray(n: number): any[] {
  return Array.from({ length: n }, (_, i) => i + 1);
}
function combinations(n: number, r: number): number {
  return factorial(n) / (factorial(r) * factorial(n - r));
}

function factorial(x: number): number {
  let result = 1;
  for (let i = 1; i <= x; i++) {
    result *= i;
  }
  return result;
}
export const string2Number = (value: string) => {
  const parsedNumber = parseFloat(value);
  if (parsedNumber === 0 || !isNaN(parsedNumber)) {
    return parsedNumber;
  }

  return null;
};
export const shortTime = (time: string, onlyShort: boolean = false) => {
  if (!isMobile && !onlyShort) return time;
  let t = time.split(" ");
  if (time.includes("seconds")) {
    if (time.includes("a few")) {
      return `1s`;
    }
    return `${t[0]}s`;
  } else if (time.includes("a minute")) {
    return `1m`;
  } else if (time.includes("minutes")) {
    return `${t[0]}m`;
  } else if (time.includes("an hour")) {
    return `1h`;
  } else if (time.includes("hours")) {
    return `${t[0]}h`;
  } else if (time.includes("a day")) {
    return `1D`;
  } else if (time.includes("days")) {
    return `${t[0]}D`;
  } else if (time.includes("a month")) {
    return `1M`;
  } else if (time.includes("months")) {
    return `${t[0]}M`;
  } else if (time.includes("a year")) {
    return `1Y`;
  } else {
    return `${t[0]}Y`;
  }
};
