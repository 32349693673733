export const SPACE_COLL = "space";
export const CATEGORY_COLL = "category";
export const LEAGUE_COLL = "league";
export const EVENT_COLL = "event";
export const USER_COLL = "user";
export const ORDER_COLL = "order";
export const REFERRAL_CHAIN_COLL = "referral";
export const TRANSACTIONS_CHAIN_COLL = "transactions";
export const WAGER_COLL = "wagers";
export const BETTING_HISTORY = "betting_history";
export const PAYOUT_HISTORY = "payout_history";
export const USER_WAGER = "user_wager";
export const WHITELIST = "whiteList";
export const ORDER_LOGS = "order_logs";
export const OUTCOME_COLL = "outcomes";
